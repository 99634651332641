import React from "react";
import { Toaster } from "react-hot-toast";
import BlockAnnouncements from "../components/blocks/announcements";
import Content from "../components/layout/content";
import Footer from "../components/layout/footer";
import Header from "../components/layout/header";
import ResponsiveSizes from "../components/layout/responsive-sizes";

export interface LayoutProps {
  children: React.ReactNode;
  pageContext: {
    headerMode?: "hidden";
  };
}

const Layout: React.FC<LayoutProps> = ({ children, pageContext }) => {
  return (
    <>
      <BlockAnnouncements />
      <Header
        className="flex-shrink-1"
        mode={pageContext.headerMode || "default"}
      />
      <Content className="flex-grow">{children}</Content>
      <Footer className="flex-shrink-1" />
      <Toaster />
      <ResponsiveSizes />
    </>
  );
};

export default Layout;
