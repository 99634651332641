import React from "react";
import AnimateHeight from "react-animate-height";
import IconMinus from "./icons/minus";
import IconPlus from "./icons/plus";

export interface DisclosureNavProps {
  title: string;
  children: React.ReactNode;
  initialOpen?: boolean;
}

const DisclosureNav: React.FC<DisclosureNavProps> = ({
  title,
  children,
  initialOpen,
}) => {
  const [open, setOpen] = React.useState(initialOpen);

  const handleDisclosureClick = React.useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div className="relative">
      <button
        className="flex items-center justify-between w-full px-4 py-2 text-left rounded nav-link"
        onClick={handleDisclosureClick}
      >
        <p>{title}</p>
        {open ? (
          <IconMinus className="flex-shrink-0 w-4 h-4 -mt-px" />
        ) : (
          <IconPlus className="flex-shrink-0 w-4 h-4 -mt-px" />
        )}
      </button>
      <AnimateHeight
        id={"sliding_wrapper"}
        duration={300}
        height={open ? "auto" : 0}
      >
        <div className="flex flex-col w-full gap-2 pl-4 max-w-none">
          {children}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default DisclosureNav;
