import classNames from "classnames";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { dispatch, useStoreState } from "../../state";
import { MenuLink } from "../../types/types";
import FlyoutNav from "../flyout-nav";
import IconMenu from "../icons/menu";
import HeaderIcon from "./header-icon";

export type HeaderQueryData = {
  navigation: {
    main: MenuLink[];
  };
  logoOneLine: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  logoTwoLines: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

export interface HeaderNavbarProps {
  mode?: "default" | "in-page";
}

const HeaderNavbar = ({ mode = "default" }: HeaderNavbarProps) => {
  const showMobileNav = useStoreState("showMobileNav");

  const {
    navigation: { main: mainNavigation },
    logoOneLine: {
      childImageSharp: { gatsbyImageData: logoOneLine },
    },
    logoTwoLines: {
      childImageSharp: { gatsbyImageData: logoTwoLines },
    },
  }: HeaderQueryData = useStaticQuery(query);

  const mainNavigationFiltered = React.useMemo(() => {
    return mainNavigation.filter((i) => i.active);
  }, [mainNavigation]);

  const handleMobileTriggerClick = React.useCallback(() => {
    dispatch({
      type: "showMobileNav",
      payload: !showMobileNav,
    });
  }, [showMobileNav]);

  return (
    <div className="w-full transition-transform bg-white border-b">
      <div
        className={classNames([
          {
            "justify-between": mode === "default",
            "justify-center": mode === "in-page",
          },
          "container-7xl py-6 flex items-center",
        ])}
      >
        {mode === "default" && (
          <Link
            to="/"
            className="flex items-center justify-center space-x-3 outline-primary-500 outline-offset-8"
          >
            <HeaderIcon />
            <GatsbyImage
              className="lg:!hidden h-8 w-[99px]"
              image={logoTwoLines}
              alt="Logo"
            />
            <GatsbyImage
              className="!hidden lg:!block h-6 w-[228px]"
              image={logoOneLine}
              alt="Logo"
            />
          </Link>
        )}

        <nav className="hidden md:block">
          <ul className="flex items-center space-x-6 bg-green">
            {mainNavigationFiltered.map((navItem) =>
              navItem.subnavigation && navItem.subnavigation.length > 0 ? (
                <FlyoutNav link={navItem} key={navItem.slug} />
              ) : (
                <li key={navItem.slug}>
                  <Link
                    className={classNames([
                      {
                        large: mode === "in-page",
                      },
                      "nav-link",
                    ])}
                    to={navItem.slug}
                  >
                    {navItem.label}
                  </Link>
                </li>
              )
            )}
          </ul>
        </nav>

        <button
          className="flex items-center justify-center w-8 h-8 md:hidden"
          onClick={handleMobileTriggerClick}
          title="Mobile Navigation"
        >
          <IconMenu className="w-6 h-6 text-primary-500" />
        </button>
      </div>
    </div>
  );
};

const query = graphql`
  query HeaderNavbarQuery {
    navigation: navigationJson {
      main {
        label
        slug
        active
        subnavigation {
          label
          slug
          active
        }
      }
    }
    logoOneLine: file(relativePath: { eq: "logo-type-oneline.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    logoTwoLines: file(relativePath: { eq: "logo-type-twolines.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;

export default HeaderNavbar;
