import { Transition } from "@headlessui/react";
import classNames from "classnames";
import { Link } from "gatsby";
import React from "react";
import { MenuLink } from "../types/types";

export interface FlyoutNavProps {
  link: MenuLink;
}

const HOVER_TIMOUT = 250;

const FlyoutNav: React.FC<FlyoutNavProps> = ({ link }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const hoverTimeout = React.useRef<NodeJS.Timeout>();

  React.useEffect(() => {
    return () => {
      if (hoverTimeout.current) {
        clearTimeout(hoverTimeout.current);
      }
    };
  }, []);

  const handleMouseEnter = React.useCallback(() => {
    setIsOpen(true);
    if (hoverTimeout.current) {
      clearTimeout(hoverTimeout.current);
    }
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    setIsClosing(true);
    hoverTimeout.current = setTimeout(() => {
      setIsClosing(false);
      setIsOpen(false);
    }, HOVER_TIMOUT);
  }, []);

  const handleClickMain = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClickSub = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="relative"
    >
      <Link to={link.slug} className="nav-link" onClick={handleClickMain}>
        {link.label}
      </Link>
      <Transition
        show={isOpen}
        className={classNames(["z-[888] absolute top-9 -left-4"], {
          "!z-[999]": isOpen && !isClosing,
        })}
        enter="transition-all duration-150"
        enterFrom="opacity-0 scale-75"
        enterTo="opacity-100 scale-100"
        leave="transition-all duration-150"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-75"
      >
        <div className="flex flex-col gap-2 p-2 bg-white border rounded-lg w-60">
          {link.subnavigation!.map((child) => (
            <Link
              key={child.slug}
              to={child.slug}
              onClick={handleClickSub}
              className="block p-2 text-sm transition-colors rounded text-stone-700 hover:text-stone-800 hover:bg-stone-50"
              activeClassName="bg-primary-500 !text-white hover:!bg-primary-500 hover:!text-white"
              partiallyActive={true}
            >
              {child.label}
            </Link>
          ))}
        </div>
      </Transition>
    </div>
  );
};

export default FlyoutNav;
