exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-abo-kiste-mdx": () => import("./../../../src/pages/abo-kiste.mdx" /* webpackChunkName: "component---src-pages-abo-kiste-mdx" */),
  "component---src-pages-datenschutz-mdx": () => import("./../../../src/pages/datenschutz.mdx" /* webpackChunkName: "component---src-pages-datenschutz-mdx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-impressum-mdx": () => import("./../../../src/pages/impressum.mdx" /* webpackChunkName: "component---src-pages-impressum-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-projekte-benjeshecke-mdx": () => import("./../../../src/pages/projekte/benjeshecke.mdx" /* webpackChunkName: "component---src-pages-projekte-benjeshecke-mdx" */),
  "component---src-pages-projekte-market-garden-mdx": () => import("./../../../src/pages/projekte/market-garden.mdx" /* webpackChunkName: "component---src-pages-projekte-market-garden-mdx" */),
  "component---src-pages-projekte-streuobstwiese-mdx": () => import("./../../../src/pages/projekte/streuobstwiese.mdx" /* webpackChunkName: "component---src-pages-projekte-streuobstwiese-mdx" */),
  "component---src-pages-projekte-tsx": () => import("./../../../src/pages/projekte.tsx" /* webpackChunkName: "component---src-pages-projekte-tsx" */),
  "component---src-pages-ueber-uns-mdx": () => import("./../../../src/pages/ueber-uns.mdx" /* webpackChunkName: "component---src-pages-ueber-uns-mdx" */)
}

