import { createStore } from "react-hooks-global-state";

export type State = {
  showMobileNav: boolean;
};

export type Action = ActionShowMobileNav;

export type ActionShowMobileNav = {
  type: "showMobileNav";
  payload: boolean;
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case "showMobileNav":
      return { ...state, showMobileNav: action.payload };
    default:
      return state;
  }
};

const initialState: State = { showMobileNav: false };

const { dispatch, useStoreState } = createStore(reducer, initialState);

export { dispatch, useStoreState };
