import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

export type AnnouncementsQueryData = {
  announcements: {
    announcements: {
      text: string;
      cta_text?: string;
      cta_target?: string;
      active: boolean;
    }[];
  };
};

const BlockAnnouncements = () => {
  const {
    announcements: { announcements },
  }: AnnouncementsQueryData = useStaticQuery(query);

  const filteredAnnouncements = React.useMemo(() => {
    return announcements.filter((a) => a.active);
  }, [announcements]);

  return filteredAnnouncements.length > 0 ? (
    <div className="relative z-50 py-2 text-white bg-fuchsia-700">
      {filteredAnnouncements.map((announcement) => (
        <div key={announcement.text} className="text-center container-7xl">
          {announcement.text}
          {announcement.cta_text && announcement.cta_target && (
            <Link className="ml-2 link link_dark" to={announcement.cta_target}>
              {announcement.cta_text}
            </Link>
          )}
        </div>
      ))}
    </div>
  ) : null;
};

const query = graphql`
  query AnnouncementsQuery {
    announcements: announcementsJson {
      announcements {
        text
        cta_text
        cta_target
        active
      }
    }
  }
`;

export default BlockAnnouncements;
