import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import classNames from "classnames";
import React from "react";
import HeaderMobileDialog from "./header-mobile-dialog";
import HeaderNavbar from "./header-navbar";

export interface HeaderProps {
  className?: string;
  mode: "hidden" | "default" | "floating";
}

export type NavState = {
  pinned: boolean;
  unpinned: boolean;
  top: boolean;
  not_top: boolean;
  bottom: boolean;
  not_bottom: boolean;
};

const triggerY = -500;

const Header = ({ className, mode }: HeaderProps) => {
  const [navState, setNavState] = React.useState<NavState>({
    pinned: false,
    unpinned: true,
    top: true,
    not_top: false,
    bottom: false,
    not_bottom: true,
  });

  useScrollPosition(
    ({ currPos }) => {
      const pinned = currPos.y <= triggerY;
      const top = currPos.y === 0;
      const bottom = currPos.y === 0;

      const newNavState = {
        pinned,
        unpinned: !pinned,
        top,
        not_top: !top,
        bottom,
        not_bottom: !bottom,
      };

      setNavState(newNavState);
    },
    [navState]
  );

  return (
    <div className="header">
      {mode !== "hidden" && <HeaderNavbar />}
      <div
        className={classNames([
          "fixed w-full z-50 top-0 transition-transform",
          {
            "translate-y-0": navState.pinned,
            "-translate-y-full": navState.unpinned,
          },
          className,
        ])}
      >
        <HeaderNavbar />
      </div>
      <HeaderMobileDialog />
    </div>
  );
};

export default Header;
