import classNames from "classnames";
import React from "react";

export interface ContentProps {
  className?: string;
  children?: React.ReactNode;
}

const Content = ({ className, children }: ContentProps) => {
  return <div className={classNames([className])}>{children}</div>;
};

export default Content;
