import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";

const interval = 10000;

export type HeaderIconQueryData = {
  allFile: {
    edges: {
      node: {
        childImageSharp: {
          gatsbyImageData: IGatsbyImageData;
        };
      };
    }[];
  };
};

const HeaderIcon = () => {
  const {
    allFile: { edges: icons },
  }: HeaderIconQueryData = useStaticQuery(query);

  const [iconIndex, setIconIndex] = React.useState<number>(0);
  const iconChangeInterval = React.useRef<NodeJS.Timer>();

  React.useEffect(() => {
    iconChangeInterval.current = setInterval(updateIcon, interval);
    return () => {
      clearInterval(iconChangeInterval.current);
    };
  }, []);

  const updateIcon = React.useCallback(() => {
    setIconIndex(Math.floor(Math.random() * icons.length));
  }, []);

  return (
    <div className="w-8 h-8">
      <GatsbyImage
        image={icons[iconIndex].node.childImageSharp.gatsbyImageData}
        alt="Gemüse"
        objectFit="contain"
        className="w-8 h-8"
      />
    </div>
  );
};

const query = graphql`
  query HeaderIconQuery {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        dir: { regex: "/assets/images/veggies/" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(
              width: 32
              height: 32
              placeholder: NONE
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
    }
  }
`;

export default HeaderIcon;
