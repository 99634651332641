import { Dialog } from "@headlessui/react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { dispatch, useStoreState } from "../../state";
import { MenuLink } from "../../types/types";
import DisclosureNav from "../disclosure-nav";
import IconX from "../icons/x";

export type HeaderMobileDialogQueryData = {
  navigation: {
    main: MenuLink[];
  };
  logoOneLine: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
  logoTwoLines: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
};

const HeaderMobileDialog = () => {
  const showMobileNav = useStoreState("showMobileNav");

  const {
    navigation: { main: mainNavigation },
    logoTwoLines: {
      childImageSharp: { gatsbyImageData: logoTwoLines },
    },
  }: HeaderMobileDialogQueryData = useStaticQuery(query);

  const mainNavigationFiltered = React.useMemo(() => {
    return mainNavigation.filter((i) => i.active);
  }, [mainNavigation]);

  const handleTriggerClick = React.useCallback(() => {
    dispatch({
      type: "showMobileNav",
      payload: !showMobileNav,
    });
  }, [showMobileNav]);

  return (
    <Dialog
      open={showMobileNav}
      onClose={handleTriggerClick}
      className="relative z-50"
    >
      <div className="fixed inset-0 overflow-y-auto bg-white">
        <div className="flex items-center justify-center">
          <Dialog.Panel className="w-full h-full mx-auto">
            <div className="flex flex-row justify-between px-6 py-6">
              <Link
                to="/"
                className="flex items-center gap-2 flew-row"
                onClick={handleTriggerClick}
              >
                <GatsbyImage
                  className="h-8 w-[99px]"
                  image={logoTwoLines}
                  alt="Logo"
                />
              </Link>
              <button
                className="flex items-center justify-center w-8 h-8"
                onClick={handleTriggerClick}
              >
                <IconX className="w-6 h-6 text-primary-500" />
              </button>
            </div>
            <hr className="border-t" />
            <div className="flex flex-col gap-4 p-4">
              {mainNavigationFiltered.map((navItem) =>
                navItem.subnavigation && navItem.subnavigation.length > 0 ? (
                  <DisclosureNav
                    title={navItem.label}
                    key={navItem.slug}
                    // initialOpen
                  >
                    <Link
                      className="block px-4 py-2 rounded nav-link "
                      activeClassName="bg-primary-500 text-white"
                      to={navItem.slug}
                      onClick={handleTriggerClick}
                    >
                      Alle anzeigen
                    </Link>
                    {navItem.subnavigation.map((navItem2) => (
                      <div key={navItem2.slug}>
                        <Link
                          to={navItem2.slug}
                          className="block px-4 py-2 rounded nav-link"
                          activeClassName="bg-primary-500 text-white"
                          onClick={handleTriggerClick}
                        >
                          {navItem2.label}
                        </Link>
                      </div>
                    ))}
                  </DisclosureNav>
                ) : (
                  <Link
                    key={navItem.slug}
                    to={navItem.slug}
                    className="px-4 py-2 rounded nav-link"
                    activeClassName="bg-primary-500 text-white"
                    onClick={handleTriggerClick}
                  >
                    {navItem.label}
                  </Link>
                )
              )}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

const query = graphql`
  query HeaderMobileDialogQuery {
    navigation: navigationJson {
      main {
        label
        slug
        active
        subnavigation {
          label
          slug
          active
        }
      }
    }
    logoTwoLines: file(relativePath: { eq: "logo-type-twolines.png" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;

export default HeaderMobileDialog;
